@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@200;400;600;800&display=swap');

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?bv6bgl');
  src:  url('../fonts/icomoon.eot?bv6bgl#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?bv6bgl') format('truetype'),
  url('../fonts/icomoon.woff?bv6bgl') format('woff'),
  url('../fonts/icomoon.svg?bv6bgl#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

html, body, html * {
  font-family: 'Roboto', sans-serif;
}
