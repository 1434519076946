.news__container__item {


  &:last-of-type {
    border-bottom: none;
    //border-bottom: 2px solid $color-gray;
  }

  &:first-of-type {
    .news__container__wrapper {
      padding-top: 0;
    }
  }

  .news__container__index {
    width: 45px;
    height: 40px;
    background: $color-blue;
    color: white;
    -webkit-clip-path: polygon(0 0, 90% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 90% 0, 100% 100%, 0% 100%);
    text-align: center;
    padding-top: 10px;
    position: relative;

    span {
      position: relative;
      left: -2px;
    }
  }

  .article-block-redirect-button {
    font-weight: 600;
    margin-right: 10px;
    color: #1d2654;
    span {
      &:first-of-type {
        margin-right: 10px;
      }
    }
  }

  @media (max-width: 991px) {
    .news-hidden-content {
      display: none;
    }
  }

  @media (min-width: 992px) {
    .news-hidden-content-shower {
      display: none;
    }
  }
}

.news__container__wrapper {
  padding: 20px 0 20px 0;

  font-size: 14px;

  .news__container__row {
    margin-bottom: 15px;
  }

  .news__container__header {
    font-size: 1.3rem;
    font-weight: 600;
    color: $color-blue;
    margin-top: 0;
    margin-bottom: 0;

    @media (max-width: 1300px) {
      font-size: 1.45em !important;
    }

    @media (max-width: 1200px) {
      font-size: 1.4em !important;
    }

    @media (max-width: 1100px) {
      font-size: 1.35em !important;
    }

    @media (max-width: 1050px) {
      font-size: 1.3em !important;
    }

    @media (max-width: 991px) {
      font-size: 1.25em !important;
    }
  }

  .news__container__row--date {
    font-weight: 600;
    margin-bottom: 25px;
  }

  .news__container__read-button {
    @extend .jump-to-button;
  }

  .decor-line {
    width: 65px;
    height: 8px;
    background: #fec969;
    display: block;
  }

  .news__background__wrapper {
    //min-height: 240px;
    //background: url(/static/vendors/images/img.png);
    background-size: cover !important;
    background-repeat: no-repeat !important;
    min-height: 280px;
    background-position-x: center !important;

    .news__background__inner {
      position: relative;
      min-height: 280px;
      //padding-right: 0 !important;

      @media (min-width: 1199px) and (max-width: 1300px) {
        width: 60%;
      }

      @media (min-width: 1099px) and (max-width: 1200px) {
        width: 65%;
      }

      @media (min-width: 992px) and (max-width: 1100px) {
        width: 70%;
      }

      .news__container__row--tags {
        .trapezoid {
          margin-top: 25px;
          display: inline-block;
          margin-right: 15px;

          &::after {
            background: #0066B3;
            height: calc(100% + 10px);
            width: 35px;
            top: -6px;
          }

          span {
            color: white;
            margin-left: 10px;
          }
        }
      }

      .news__shade__wrapper {
        width: calc(100% + 60px);
        background: $color-blue;
        height: 100%;
        position: absolute;
        opacity: 0.8;
        left: 0;
        top: 0;
        z-index: 1;
        -webkit-clip-path: polygon(0 0, 88% 0, 100% 100%, 0% 100%);
      }

      h4 {
        position: relative;
        z-index: 2;
        color: $color-white;
        font-size: 1.5rem;
        opacity: 1;
      }
    }
  }
}

.news__content {
  margin-bottom: 80px;
  .news__content__item {
    @media (min-width: 992px) {
      .news__container__wrapper__position--1 {
          margin-right: 25px;
      }

      .news__container__wrapper__position--2 {
          margin-right: 25px;
          margin-left: 25px;
      }

      .news__container__wrapper__position--3 {
          margin-left: 25px;
      }

      .news__container__wrapper.without-separator {
        border-bottom: none;
      }
    }

    @media (max-width: 991px) {
      .news__container__wrapper.without-separator {
        border-bottom: 2px solid $color-gray;
      }
      .news__container__wrapper:last-of-type {
        border-bottom: none;
      }

    }
  }
}
