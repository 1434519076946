.navigation {
  &.show {
    //margin-bottom: 65px;

    @media (max-width: 991px) {
      margin-bottom: unset;
    }
  }

  .navigation__hamburger {
    width: 40px;
    background: $color-blue;
    color: $color-white;
    height: 40px;
    border: unset;
    position: absolute;
    right: 60px;
    top: 28px;
    border: 1px solid $color-blue;
    display: none;

    @media (max-width: 1199px) {
      display: block;
      left: calc(100% - 410px);
      right: unset;
    }

    @media (max-width: 991px) {
      left: unset;
      right: 60px;
    }

    &:hover {
      background: $color-gray;
      color: $color-blue;
    }
  }

  .navigation__logo {
    position: relative;
    height: 95px;

    @media (min-width: 992px) {
      width: 255px;
    }

    @media (max-width: 991px) {
      width: 100%;
    }

    img {
      max-width: 170px;
      max-height: 102px;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 42px;
      position: absolute;

      @media (max-width: 991px) {
        max-height: 75px;
      }
    }
  }

  .navigation__internal {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-right: 20px;

    @media (max-width: 991px) {
      margin-top: 0;
      margin-bottom: 0;
    }

    @media (max-width: 1199px) {
      &.show {
        ul {
          display: block;
          position: absolute;
          background: white;
          padding: 30px 60px;
          z-index: 9999;
          background: $color-white;
          top: 95px;
          width: 100%;
          -webkit-box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.15);
          -moz-box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.15);
          box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.15);
          left: 0;

          li {
            display: block;

            &:not(:last-of-type) {
              margin-bottom: 15px;
            }

            .navigation__internal__dropdown {
              width: 100%;
              position: relative;

              ul {
                position: relative;
                border-bottom: unset;
                -webkit-box-shadow: unset;
                -moz-box-shadow: unset;
                box-shadow: unset;
              }
            }
          }
        }
      }
    }

    @media (min-width: 991px) {
      width: calc(100% - 605px);
    }

    @media (max-width: 991px) {
      &.navigation__internal.show > ul {
        padding-top: 115px !important;
      }
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: space-between;

      @media (max-width: 1199px) {
        display: none;
      }

      @media (min-width: 1500px) {
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
      }

      @media (max-width: 991px) {
        justify-content: unset;
        width: fit-content;
        margin: auto;
      }

      li {
        min-width: 120px;
        position: relative;

        @media (max-width: 991px) {
          &:not(:first-of-type) {
            margin-top: 20px;
          }

          display: inline-block;
        }

        @media (max-width: 699px) {
          display: block;
        }

        &.active {
          .navigation__internal__dropdown {
            display: block;
          }
        }

        @media (max-width: 1199px) {
          &:last-of-type {
            .navigation__internal__dropdown {
              right: 10px;
            }
          }
        }

        @media (max-width: 991px) {
          &:last-of-type {
            .navigation__internal__dropdown {
              right: unset;
            }
          }
        }

        .navigation__internal__dropdown {
          display: none;
          position: absolute;
          top: 100%;
          padding-top: 10px;
          z-index: 9999;

          ul {
            display: block;
            padding: 10px;
            background: $color-gray;
            border-bottom: 4px solid $color-blue;
            -webkit-box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.15);
            -moz-box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.15);
            box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.15);
            top: unset;

            li {
              display: block;

              &:not(:last-of-type) {
                margin-bottom: 10px;
              }

              a {
                &.active {
                  .trapezoid {
                    span::before {
                      width: 38px;
                    }
                  }
                }

                .trapezoid {
                  font-size: 14px;
                  padding: 6px 10px;

                  &:hover {
                    span::before {
                      width: 38px;
                    }
                  }

                  &::after {
                    background: $color-white;
                    width: 38px;
                  }
                }
              }
            }
          }
        }

        .navigation__internal__uri {
          text-decoration: unset;

          &.active {
            .trapezoid {
              span::before {
                content: '';
                background: $color-blue;
                height: 5px;
                width: 53px;
                z-index: 999;
                position: absolute;
                display: block;
                bottom: 0;
                left: 0;
              }
            }
          }

          .trapezoid {
            &:hover {
              cursor: pointer;

              span::before {
                content: '';
                background: $color-blue;
                height: 5px;
                width: 53px;
                z-index: 999;
                position: absolute;
                display: block;
                bottom: 0;
                left: 0;
              }
            }

            &::after {
              width: 53px;
            }

            font-size: 16px;
            margin-left: 0;
            margin-top: 0;
            padding: 12px 17px;
          }
        }
      }
    }
  }

  .navigation__external {
    padding: 20px 60px 20px 20px;
    background: $color-blue;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 3% 100%);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 3% 100%);

    @media (max-width: 991px) {
      display: none;

      &.show {
        display: block;
        position: absolute;
        top: 95px;
        min-width: 300px;
      }
    }

    @media (min-width: 992px) {
      width: 290px !important;
      padding: 20px 0 20px 20px !important;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 3% 100%) !important;
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 3% 100%) !important;
      position: relative;
    }

    @media (max-width: 991px) {
      width: 100%;
      clip-path: unset;
      text-align: center;
      padding: 20px 20px 10px 20px;
      z-index: 9999;
    }

    @media (max-width: 991px) {
      width: 100%;
      clip-path: unset;
      text-align: center;
      padding: 20px 20px 10px 20px;
    }

    @media (max-width: 499px) {
      text-align: unset;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      float: left;
      display: inline-block;

      @media (max-width: 1550px) {
        float: unset;
      }

      @media (max-width: 499px) {
        display: block;
        width: fit-content;
        margin: auto;
      }

      @media (max-width: 991px) {
        text-align: center;
      }

      li {
        position: relative;
        font-size: 16px;
        margin-top: 0;
        padding: 12px 0 12px 12px;
        font-weight: bold;
        color: $color-white;
        display: inline-block;
        min-width: 50px;
        margin-left: 10px;
        top: -7px;

        @media (max-width: 991px) {
          display: inline-block;
          margin-left: 0;
        }

        a {
          color: $color-white;
        }

        &:hover {
          cursor: pointer;

          &::before {
            content: '';
            background: $color-white;
            height: 5px;
            width: 40px;
            z-index: 999;
            position: absolute;
            display: block;
            bottom: 0;
            left: 12px;
          }
        }

        input {
          padding: 0 !important;
        }

        span {
          &::before {
            font-size: 26px;
            top: 7px;
            position: relative;
          }
        }
      }
    }
  }

  #set-language-form {
    [type="submit"] {
      background: transparent;
      border: 0;
      color: $color-white;
      position: relative;
      font-size: 16px;
      font-weight: bold;
    }
  }

  &.menu-version-sk {
    .menu-version__only-en {
      display: none;
    }
  }

  &.menu-version-en {

    .menu-version__only-sk {
      display: none;
    }

    .navigation__internal {
      @media (min-width: 991px) {
        width: calc(100% - 548px);
      }
    }

    .navigation__external {
      @media (min-width: 991px) {
        width: 233px !important;
      }
    }
  }
}
