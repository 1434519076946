[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-twitter:before {
  content: "\e928";
}
.icon-arrows:before {
  content: "\e927";
}
.icon-mail-physical:before {
  content: "\e920";
}
.icon-press:before {
  content: "\e921";
}
.icon-research:before {
  content: "\e905";
}
.icon-education:before {
  content: "\e906";
}
.icon-service:before {
  content: "\e907";
}
.icon-shop:before {
  content: "\e908";
}
.icon-book:before {
  content: "\e909";
}
.icon-hospital:before {
  content: "\e90a";
}
.icon-recycle:before {
  content: "\e90d";
}
.icon-business:before {
  content: "\e914";
}
.icon-construction:before {
  content: "\e915";
}
.icon-test:before {
  content: "\e916";
}
.icon-food:before {
  content: "\e917";
}
.icon-home:before {
  content: "\e918";
}
.icon-it:before {
  content: "\e919";
}
.icon-energy:before {
  content: "\e91a";
}
.icon-network:before {
  content: "\e91b";
}
.icon-agriculture:before {
  content: "\e91c";
}
.icon-money:before {
  content: "\e91d";
}
.icon-tree:before {
  content: "\e91e";
}
.icon-crane:before {
  content: "\e91f";
}
.icon-instagram:before {
  content: "\e904";
}
.icon-email:before {
  content: "\e902";
}
.icon-phone:before {
  content: "\e903";
}
.icon-linkedin:before {
  content: "\e900";
}
.icon-facebook:before {
  content: "\e901";
}
.icon-add:before {
  content: "\e911";
}
.icon-edit:before {
  content: "\e912";
}
.icon-arrow_right:before {
  content: "\e913";
}
.icon-sort_desc:before {
  content: "\e90f";
}
.icon-sort_asc:before {
  content: "\e910";
}
.icon-delete:before {
  content: "\e90e";
}
.icon-menu:before {
  content: "\e90c";
}
.icon-menu_open:before {
  content: "\e90b";
}
.icon-cross:before {
  content: "\ea0f";
}
.icon-download:before {
  content: "\e960";
}
.icon-search:before {
  content: "\e986";
}
.icon-calendar:before {
  content: "\e922";
}
.icon-chevron-right:before {
  content: "\e923";
}
.icon-chevron-left:before {
  content: "\e924";
}
.icon-chevron-down:before {
  content: "\e925";
}
.icon-chevron-up:before {
  content: "\e926";
}
