.swal2-container.swal2-center>.swal2-popup {
  border-radius: 0;
  width: 40em;
  padding: 25px;
  padding-bottom: 45px;
  .swal2-title {
    font-family: "Barlow", sans-serif;
    font-size: 30px;
    position: relative;
    color: $color-blue;
  }
  .swal2-actions {
    margin-top: 30px;
    button {
      border-radius: 0;
    }
  }
}
