.footer {
  margin-left: -15px;
  margin-right: -15px;

  @media (max-width: 991px) {
    .footer-contacts {
      width: 100% !important;
      text-align: center;
    }
  }

  .footer-block {
    padding: 15px;
    position: relative;
    z-index: 1;

    @media (max-width: 991px) {
      width: (100/12) * 6%;
    }

    @media (max-width: 799px) {
      width: 100%;
    }

    .img-wrapper {
      display: block;
      width: 100%;
      padding-left: 12px;
      background: transparent;

      &:first-of-type {
        margin-top: 5px;
      }

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }

      &:nth-of-type(4) {
        padding-left: 19px;

        div {
          max-width: 120px;
        }
      }

        &:nth-of-type(5) {
        padding-left: 19px;

        div {
          max-width: 120px;
        }
      }

      div {
        height: 35px;
        max-width: 155px;
        width: 100%;
        background-repeat: no-repeat;
        background-position: left;
        background-size: contain;
      }
    }

    .footer-block--with-bg {
      padding: 15px;
      height: 100%;
      background: $color-gray;
      clip-path: polygon(0 0, 95% 0, 100% 100%, 0% 100%);
      -webkit-clip-path: polygon(0 0, 95% 0, 100% 100%, 0% 100%);
    }

    .footer-block--without-bg {
      padding: 15px;
      height: 100%;
      background: $color-white;
    }

    .footer-block__header {
      display: block;
      margin-bottom: 0;
      text-decoration: unset;

      &.active {
        .trapezoid {
          span::before {
            content: '';
            background: $color-blue;
            height: 5px;
            width: 45px;
            z-index: 999;
            position: absolute;
            display: block;
            bottom: 0;
            left: 0;
          }
        }
      }

      .trapezoid {
        &:hover {
          cursor: pointer;

          span::before {
            content: '';
            background: $color-blue;
            height: 5px;
            width: 45px;
            z-index: 999;
            position: absolute;
            display: block;
            bottom: 0;
            left: 0;
          }
        }

        &::after {
          background: $color-white;
          width: 45px;
        }

        font-size: 16px;
        margin-left: 0;
        margin-top: 0;
        padding: 12px 17px;
      }
    }

    .footer-block__content--text {
      color: $color-blue;
      font-weight: bold;
      font-size: 16px;
      padding: 12px 17px;
    }

    .footer-block__content--normal {
      display: block;
      margin-top: 5px;
      padding-left: 17px;
      padding-right: 34px;
      color: $color-black;
      font-weight: 400;
      font-size: 14px;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .footer-block__content--header {
      &:not(:first-of-type) {
        margin-top: 20px;
      }

      &:last-of-type {
        h4 {
          margin-bottom: 0;
        }
      }
    }

    .footer-block__text {
      margin-top: 5px;

      @media (max-width: 575px) {
        margin: auto;
      }

      .footer-block__text--bold {
        font-weight: bold;
        font-size: 14px;
        text-transform: uppercase;
        line-height: 22px;
        display: block;
      }

      .footer-block__text--normal {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        display: block;
      }
    }
  }
}
