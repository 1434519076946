.partners {
  .partners__content {
    margin: 30px -15px 137px -15px;

    .partners__content__column {
      padding: 15px;
      position: relative;

      @media (max-width: 1350px) {
        width: (100/12) * 6%;
      }

      @media (max-width: 850px) {
        width: 100%;
      }

      .partners__content__column__image {
        position: absolute;
        background: $color-white;
        z-index: 10;
        border-radius: 100px;
        text-align: center;
        top: 75px;
        border: 5px solid $color-white;

        div {
          height: 127px;
          width: 127px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          border-radius: 50%;

          @media (max-width: 440px) {
            height: 94px;
            width: 94px;
            top: 75px;
          }
        }
      }

      .partners__content__wrapper {
        z-index: 1;
        background: $color-gray;
        clip-path: polygon(5% 0, 100% 0, 100% 100%, 0% 100%);
        -webkit-clip-path: polygon(5% 0, 100% 0, 100% 100%, 0% 100%);
        width: calc(100% - 75px);
        float: right;

        @media (max-width: 440px) {
          width: calc(100% - 55px);
        }

        .partners__content__wrapper__text {
          padding: 40px 40px 30px 80px;

          @media (max-width: 1000px) {
            padding-left: 80px;
          }

          .partners__content__wrapper__text--header {
            color: $color-blue;
            font-size: 18px;
            margin-bottom: 20px;
          }

          .partners__content__wrapper__text--context {
            font-size: 13px;
            color: $color-black;
            display: block;
            line-height: 20px;
            margin-bottom: 20px;
            overflow: auto;
          }

          .partners__content__wrapper__text--arrow {
            a {
              font-weight: bold;
              text-decoration: unset;
              font-size: 13px;
              color: $color-black;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}
