.home-wrapper {
  @media (max-width: 991px) {
    .base-header--line-after__title {
      font-size: 22px !important;
    }
  }
}

.home-banner-wrapper {
  padding-bottom: 30px;

  .banner__desktop {
    display: none;
    text-align: center;
    background: $color-blue;

    @media (min-width: 992px) {
       display: block;
    }

    img {
      max-width: 80%;
    }
  }

  .banner__mobile {
    display: none;
    text-align: center;
    background: $color-blue;

    @media (max-width: 991px) {
       display: block;
    }

    img {
      max-width: 80%;
    }
  }
}

@media (max-width: 991px) {
  .mobile-hide {
    display: none;
  }
}
