.newsletter-container__wrapper {
  height: 60px;
  border-left: 5px solid $color-blue;

  .newsletter-container__input-wrapper {
    width: 79%;
    display: inline-block;

    input {
      font-weight: 500;
      width: 100%;
      height: 60px;
      border: 0;
      background: $color-gray;
      padding: 15px;
      color: $color-blue;

      &::placeholder {
        color: $color-blue;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .newsletter-container__button-wrapper {
    width: 20%;
    display: inline-block;

    button {
      text-wrap: none;
      height: 60px;
      background-color: $color-blue;
      color: white;
      border: none;
      padding: 0 24px;
      position: relative;
      top: 1px;
      clip-path: polygon(5% 0, 100% 0, 100% 100%, 0% 100%);
      left: -30px;
      font-size: 18px;
      white-space: nowrap;
    }
  }
}

.newsletter-container__info-wrapper {
  text-align: center;
  font-size: 13px;
  color: gray;
  padding: 15px;
  padding-right: 58px;
}
