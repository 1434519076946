.projects {
  .projects__content {
    position: relative;
    margin: 0 -45px 50px -45px;

    .loader__wrapper.show {
      position: absolute;
      margin: auto;
      width: 100%;
      height: 100%;
      background: $color-white;
      z-index: 999999;
      opacity: 0.9;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 65px;
    }

    #projects__ajax_loader {
      text-align: center;
      color: $color-gray-darker;
      font-weight: 400;
      font-size: 14px;

      &.hidden {
        display: none;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .projects__content__item {
      @media (max-width: 1200px) {
        width: (100/12) * 6%;
      }

      @media (max-width: 800px) {
        width: 100%;
      }

      .articles__container__wrapper {
        padding: 30px 45px;
        margin: 0;
      }
    }

    .tags-wrapper {
      .trapezoid {
        span {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
