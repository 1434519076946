.educations {
  .educations__content {
    margin: 25px -30px 80px -30px;

    .education__articles {
      padding: 0 30px;
    }

    .educations__content__item {
      padding: 15px;

      &.education__detail {
        padding: 0 15px;
      }

      @media (max-width: 992px) {
        width: (100/12) * 6%;
      }

      @media (max-width: 700px) {
        width: 100%;
      }

      .educations__content__item__wrapper {
        background: $color-gray;
        clip-path: polygon(0 0, 97% 0, 100% 100%, 0% 100%);
        -webkit-clip-path: polygon(0 0, 97% 0, 100% 100%, 0% 100%);
        padding: 30px;

        .educations__content__item__header {
          margin-bottom: 15px;

          .educations__content__item__header__column {
            .educations__content__item__header__column__title {
              color: $color-blue;
              font-weight: bold;
              font-family: $font-family-2;
              font-size: 34px;
              display: block;

              @media (max-width: 991px) {
                font-size: 22px;
              }
            }
          }
        }

        .educations__content__item__user {
          position: relative;
          padding: 20px;
          margin-bottom: 18px;

          .educations__content__item__user__column {
            .educations__content__item__user__column__image {
              position: absolute;
              z-index: 10;
              border-radius: 100px;
              top: 7px;
              left: 0;

              div {
                width: 45px;
                height: 45px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                border-radius: 50%;
              }
            }

            .educations__content__item__user__column__user {
              span {
                font-size: 14px;
                display: block;
                margin-left: 35px;
                font-weight: bold;
                color: $color-black;
              }
            }
          }
        }

        .educations__content__item__content {
          .educations__content__item__content__column {
            .educations__content__item__content__column__text {
              font-size: 14px;
              display: block;
              color: $color-black;
              line-height: 24px;
              margin-bottom: 30px;
            }

            .educations__content__item__content__column__arrow {
              a {
                font-size: 14px;
                color: $color-blue;
                font-weight: bold;
                text-decoration: unset;
                display: block;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }

  .educations__latest {
    margin-bottom: 50px;

    .education__detail {
      float: right;
    }

    @media (max-width: 992px) {
      margin-bottom: 80px;
    }

    .content-block--left {
      .base-header--line-after {
        margin-bottom: 25px;

        .base-header--line-after__title {
          text-transform: uppercase;
        }
      }
    }

    .content-block--right {
      .base-header--line-after {
        margin-bottom: 35px;

        .base-header--line-after__title {
          text-transform: uppercase;
        }
      }
    }
  }
}
