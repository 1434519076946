.decor-line__wrapper {
  margin-bottom: 15px;

  .decor-line {
    width: 65px;
    height: 8px;
    background: #fec969;
    display: block;
  }
}

.articles__container__wrapper {
  padding: 25px;
  margin-left: -25px;
  //margin-top: 25px;
  font-size: 14px;
  padding-right: 70px;

  @media (max-width: 992px) {
    margin-right: -25px;
  }

  &.promo {
    background: $color-gray;
    -webkit-clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    padding-right: 52px;
    padding-top: 35px;
    padding-bottom: 35px;
    margin-right: 60px;
    margin-bottom: 25px;

    .articles__container {
      column-count: 2;
      column-gap: 8em;

      @media (max-width: 500px) {
        column-count: unset;
        column-gap: unset;
      }

      .col-4 {
        @media (max-width: 1400px) {
          width: (100/12) * 6% !important;
        }

        @media (max-width: 750px) {
          width: 100% !important;
          margin-bottom: 10px !important;
        }
      }

      .col-5 {
        @media (max-width: 1400px) {
          width: (100/12) * 6% !important;
        }

        @media (max-width: 750px) {
          width: 100% !important;
          margin: 17px 0 5px -2px !important;
        }
      }

      .col-3 {
        @media (max-width: 1400px) {
          width: 100% !important;
          left: 0;

          span {
            left: 12px;
            float: left;
          }
        }
      }
    }

    .trapezoid::after {
      background: $color-white;
    }

    .articles__container__header {
      font-family: $font-family-2;
      font-size: 30px;
      margin-bottom: 20px;
      position: relative;
      top: -10px;
    }
  }

  &.sector {
    background: linear-gradient(90deg, $color-gray 50%, $color-blue 50%);
    -webkit-clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
    padding-right: 52px;
    padding-top: 25px;
    padding-bottom: 25px;
    margin-right: 60px;
    margin-bottom: 25px;
    min-width: 235px;
  }

  .articles__container__row {
    margin-bottom: 15px;

    .articles__container__header {
      font-weight: 600;
      color: $color-blue;
      margin-bottom: 20px;
    }

    .articles__container__read-button {
      @extend .jump-to-button;
    }

    .articles__text {
      line-height: 1.8;
      font-size: 14px;
    }

    &.articles__container__row--tags {
      font-size: 14px;
      font-weight: 600;
    }
  }

  .articles__container__promo--date {
    position: relative;
    left: 25px;

    span {
      position: relative;
    }

    span::before {
      content: "";
      border-radius: 50%;
      background: #fff;
      height: 35px;
      width: 35px;
      display: block;
      position: absolute;
      top: -9px;
      z-index: -1;
      left: -12px;
    }
  }
}

.article-detail__wrapper {

  &.responsive {
     display: none;

     @media (max-width: 1199px) {
      display: block;
    }
  }

  &.full-width {

    @media (max-width: 1199px) {
      display: none;
    }
  }


  .article-detail__title {
    .trapezoid {
      &::after {
        max-height: 80px;
      }
      span {
        line-height: 70px;
        &::after {
          max-height: 70px;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .article-detail__title {
      .trapezoid {
        &::after {
          max-height: 66px;
        }
        span {
          line-height: 50px;
          &::after {
            max-height: 66px;
          }
        }
      }
    }
  }

  .article-detail__document-clip {
    clip-path: polygon(0 0, 95% 0, 100% 100%, 0% 100%);
    -webkit-clip-path: polygon(0 0, 95% 0, 100% 100%, 0% 100%);

    margin-top: 40px;

    &:first-of-type {
      margin-top: 0;
    }
  }

  .article-detail__document-wrapper-block {

  }

  .article-detail__document-wrapper {
    background: $color-gray;
    min-height: 125px;
    position: relative;

    .article-detail__document-wrapper--image {
      position: relative;
      top: 0;
      display: flex;
      bottom: 0;
      align-items: center;
      -webkit-box-align: center;
      //margin: auto;
      left: 15px;
      margin: 0;

      width: calc(6vw + 50px);
       max-width: 100px;

      img {
        width: 100%;
        -webkit-box-shadow: 0 0 8px -5px #000;
        box-shadow: 0 0 8px -5px #000;
        padding: 25px;
        background: $color-white;
      }

      //width: 33%;
      //max-width: 120px;
    }

    .article-detail__document-wrapper--name {
      padding: 20px;
      font-weight: 600;
      font-size: 14px;
      //padding-left: 155px;
      //padding-right: 40px;
      display: inline-block;
      position: relative;
      width: fit-content;
      margin-left: 20px;
      overflow-x: hidden;

      .document-name {
        display: block;
        color: $color-blue;
      }

      .document-size {
        display: block;
        color: $color-gray-darker;
        margin-top: 5px;
      }
    }
  }

  .article-detail__content {
    @media (max-width: 991px) {
      &.hidden {
        height: 570px;
        overflow-y: hidden;
      }
    }

    position: relative;

    p {
      min-height: 24px;
    }
    a {
      color : #3466b3;
      &:hover {
        color : $color-blue;
      }
    }

      figure {
    figcaption {
      display: none;
    }
  }

  .embed-responsive {
    iframe {
      width: 100% !important;
    }
    p {
      display: none;
    }
  }

    blockquote {
      border: 1px solid #dee2e6;
      padding: 15px 15px 0 15px;
      color: var(--c-text-default);
      font-style: italic;
      font-weight: normal;
      background: transparent;

      p {
        color: var(--c-text-default);
        font-size: 115%;
      }
    }
    figure img {
      max-width: 100%;
      height: auto;
    }

    .align-right {
      text-align: right;
    }

    .align-center {
      text-align: center;
    }

    .align-justify {
      text-align: justify;
    }

    table {
      width: 100%;
      background: $color-gray;
      margin-bottom: 10px;

      tr {
        &:first-of-type {
          td {
            white-space: nowrap;
          }
        }
        td {
          border: 3px solid white;
          padding: 10px;
          text-align: left;
          vertical-align: top;
          font-size: 95%;
          line-height: 1.5;
        }
      }
    }

    .hidden-content {
      background: linear-gradient(hsla(0,0%,100%,0),#fff);
      display: block;
      top: 430px;
      position: absolute;
      height: 140px;
      width: 100%;
    }
  }

  .tags-wrapper {
    .trapezoid {
      display: inline-block;
      margin-right: 40px;
      margin-top: 5px;
      margin-bottom: 5px;
      white-space: unset;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }


}

.articles__content__header {
  margin-bottom: 25px;

  @media (max-width: 991px) {
    &.mt-25 {
      margin-top: 25px;
    }

    .base-header--line-after__title {
      font-size: 32px !important;
    }
  }
}

.sector-left-side {
  position: relative;

  .sector-left-side__content {
    margin-bottom: -20px;

    .sector__icon {
      width: 20%;
      height: 50px;
      margin-bottom: 15px;

      @media (max-width: 1200px) {
        width: (100/12) * 3% !important;
      }

      @media (max-width: 1000px) {
        width: (100/12) * 4% !important;
      }

      @media (max-width: 600px) {
        width: (100/12) * 6% !important;
      }

      @media (max-width: 450px) {
        width: 100% !important;
      }

      .sector__icon--span {
        &:hover {
          cursor: pointer;
        }
      }

      span {
        display: block;
        padding: 10px;
        width: 50px;
        height: 50px;
        position: relative;

        &.active {
          background: $color-blue;

          &::before {
            color: $color-white;
          }
        }

        &::before {
          position: absolute;
          display: flex;
          bottom: 0;
          top: 0;
          left: 0;
          right: 0;
          justify-content: center;
          align-items: center;
          -webkit-box-align: center;
          margin: auto;
          font-size: 1.8em;
          color: $color-blue;
        }

        &.icon-energy {
          &::before {
            font-size: 1.3em;
          }
        }
      }
    }
  }
}

.sector-right-side {
  padding-left: 45px;
  padding-right: 10px;
  position: relative;

  .sector__text {
    display: none;
    opacity: 0;

    &.active {
      display: block;
      opacity: 1;
      animation: fadeIn 2s;
      -webkit-animation: fadein 2s;
      -moz-animation: fadein 2s;
      -o-animation: fadein 2s;
      -ms-animation: fadein 2s;
    }

    .sector__text--title {
      margin-bottom: 15px;

      .base-header--line-after__title {
        background: $color-blue;
      }

      .base-header--line-after__line {
        height: 1px;
      }

      span {
        color: $color-white;
      }
    }

    .sector__text--description {
      font-size: 14px;
      color: $color-white;
    }
  }

  .sector__read {
    bottom: 0;
    position: absolute;
    right: 0;

    .sector__read-button {
      a {
        color: $color-white;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}

.full-content-trigger-button-wrapper {
  text-align: center;
  height: 35px;
  .full-content-trigger-button {
    color: $color-blue;
    &:hover {
      cursor: pointer;
    }
  }
}

.articles__container__read-old-button {
  @extend .jump-to-button;

  text-align: end;

  span {
    color: $color-gray-darker;
    font-size: 14px;
    font-weight: 500;
  }
}

@media screen and (max-width: 1199px) {
  .articles__container__wrapper.promo {
    column-count: 1;

    .articles__container__promo--date {
      left: 0;
    }
  }

  .article-detail {
    .content-block--right {
      padding-left: 0 !important;
    }
  }
}

@media screen and (max-width: 991px) {
  .articles__container__wrapper {

    //padding-right: 25px;
    margin: 0;
    padding-right: 0;
    padding-left: 0;
    &.promo {
      margin-right: unset;
      padding-left: 25px;
    }
  }

  .content-block--right {
    padding-left: 0 !important;
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-moz-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-o-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-ms-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@media (max-width: 991px) {
  .articles__container__wrapper {
    .articles__container__header {
      font-size: 18px;
    }

    .articles__container {
      column-count: 1;
    }
  }

  .articles__container__header {
    font-size: 18px;
  }
}

@media (min-width: 1401px) {
  .articles__container__wrapper.promo {
    .articles__container__row--tags {
      .tags-wrapper {
        width: 50% !important;
      }

      .user-wrapper {
        width: 50% !important;
      }

      .articles__container__promo--date {
        left: 0 !important;
      }
    }
  }
}
