body {
  max-width: 1400px;
  margin: auto;

  .row {
    --bs-gutter-x: 0;
  }
}

h4 {
  font-weight: 600;
  font-family: $font-family-3;
  color: $color-blue;
  text-transform: uppercase;
  margin: 30px 0;
}

a {
  text-decoration: none;
}

.content-block {
  padding: 30px 60px 50px 60px;
}

@media (max-width: 991px) {
  .content-block {
    padding: 30px 20px 50px 20px;
  }
}



.content-block--right {
  padding-left: 35px;
}

// TEXT TRANSFORMATIONS

.trapezoid {
  &::after {
    background: $color-gray;
    content: '';
    top: 0;
    height: 100%;
    width: 25px;
    position: absolute;
    display: block;
    z-index: -1;
    left: 0;
    clip-path: polygon(0 0, 77% 0, 100% 100%, 0% 100%);
    -webkit-clip-path: polygon(0 0, 77% 0, 100% 100%, 0% 100%);
  }

  white-space: nowrap;
  position: relative;
  display: block;
  z-index: 999;
  font-weight: bold;
  color: $color-blue;
  //margin-left: 50px;
  //margin-top: 50px;
  //padding: 25px 50px;
}

h1 {
  .trapezoid {
    text-transform: uppercase;
    padding: 25px 5px;

    span {
      //font-size: 43px;
      font-family: $font-family-3;
      white-space: break-spaces;
      line-height: 80px;

      &::after {
        color: $color-white;
        content: attr(data-letters);
        z-index: 2;
        top: 25px;
        height: 100%;
        width: 34px;
        position: absolute;
        display: block;
        left: 5px;
        -webkit-clip-path: polygon(0 0, 77% 0, 100% 100%, 0% 100%);
        clip-path: polygon(0 0, 77% 0, 100% 100%, 0% 100%);
        max-height: 100px;
      }
    }

    &::after {
      height: 100%;
      max-height: 100px;
      width: 38px;
      top: 15px;
      background-color: $color-blue;
      clip-path: polygon(0 0, 77% 0, 100% 100%, 0% 100%);
      -webkit-clip-path: polygon(0 0, 77% 0, 100% 100%, 0% 100%);
    }

    &:hover {
      cursor: initial;

      span::before {
        display: none;
      }
    }
  }
}

.font-bold {
  font-weight: 600;
}

.jump-to-button {
  &:hover {
    cursor: pointer;
  }

  span {
    font-weight: 600;
    margin-right: 10px;
    color: $color-blue;
  }
}

.search-filters {
  width: 100%;
  height: 100%;
  position: relative;
  top: 15px;

  ul {
    background: $color-gray;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 3% 100%);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 3% 100%);
    list-style-type: none;
    width: fit-content;
    float: right;
    padding: 25px 25px 25px 45px;
    margin-bottom: 35px;

    li {
      display: inline-block;
      margin-right: 25px;
      margin-top: 5px;
      margin-bottom: 5px;

      .trapezoid {
        &:hover, &.active {
          cursor: pointer;

          span::before {
            content: '';
            background: $color-blue;
            height: 5px;
            width: 40px;
            z-index: 999;
            position: absolute;
            display: block;
            bottom: 0;
            left: 0;
          }
        }

        span {
          white-space: break-spaces;
        }

        &::after {
          background: $color-white;
          width: 40px;
        }

        font-size: 14px;
        margin-left: 0;
        margin-top: 0;
        padding: 9px 14px;
        color: $color-black;
      }
    }
  }
}

.base-header--line-after {
  position: relative;

  .base-header--line-after__title {
    font-family: $font-family-3;
    font-weight: bold;
    font-size: 24px;
    padding-right: 20px;
    background: $color-white;
    float: left;
    z-index: 100;
    color: $color-blue;
    text-transform: uppercase;
  }

  .base-header--line-after__line {
    position: absolute;
    width: 100%;
    background: $color-gray;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 2px;
    z-index: -1;
  }
}

.center-my-div {
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
}

.content-split {
  .content-split--header {
    font-family: $font-family-3;
    font-weight: 600;
    text-transform: uppercase;
    color: $color-blue;
  }
}

.author-info__container {
  position: relative;

  .author-info__container__image {
    position: absolute;
    background: $color-white;
    z-index: 10;
    border-radius: 100px;
    text-align: center;
    top: 45px;
    border: 5px solid $color-white;

    div {
      height: 127px;
      width: 127px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border-radius: 50%;
    }
  }

  .author-info__container__wrapper {
    background: $color-gray;
    clip-path: polygon(5% 0, 100% 0, 100% 100%, 0% 100%);
    -webkit-clip-path: polygon(5% 0, 100% 0, 100% 100%, 0% 100%);
    width: calc(100% - 90px);
    float: right;

    .author-info__container__wrapper__text {
      padding: 30px 25px 30px 60px;
      min-height: 220px;

      .author-info__container__wrapper__text__header {
        font-size: 18px;
        font-weight: bold;
        color: $color-blue;
        display: block;
      }

      .author-info__container__wrapper__text__tags {
        display: block;
        margin-bottom: 20px;

        .trapezoid {
          display: inline-block;
          padding: 5px;
          margin: 5px 0;

          &:not(:first-of-type) {
            margin-left: 10px;
          }

          &::after {
            background: $color-white;
            width: 40px;
          }

          span {
            font-size: 14px;
            font-weight: bold;
            color: $color-black;
          }
        }
      }

      .author-info__container__wrapper__text__context {
        font-size: 13px;
        line-height: 1.8;
        display: block;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .author-info__container__wrapper__text__title {
        font-size: 14px;
        line-height: 1.8;
        display: block;
        color: $color-blue;
        margin-bottom: 15px;
      }

      .author-info__container__wrapper__text__email, .author-info__container__wrapper__text__phone {
        font-size: 12px;
        display: block;
        color: $color-blue;

        a {
          color: $color-blue;
        }
      }

      .author-info__container__wrapper__text__arrow {
        a {
          font-size: 14px;
          font-weight: bold;
          color: $color-blue;
          text-decoration: unset;

          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.author__container--mini__wrapper {
  position: relative;
  top: -12px;

  .author__container--mini__image {
    width: 45px;
    height: 45px;
    //background-image: url(/static/vendors/images/author.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
  }

  .author__container--mini__name {
    padding-left: 10px;

    span {
      color: $color-black;
      font-weight: 600;
    }
  }
}

.tags-wrapper {
  margin-bottom: 10px;
  //margin-top: 30px;

  @media (max-width: 500px) {
    width: 100%;
    margin-bottom: 40px;
  }

  .trapezoid {
    height: calc(0% + 35px);

    &::after {
      top: -7px;
      width: 35px;
    }

    span {
      margin-left: 10px;
      color: $color-black;
      top: 0;
      position: relative;
    }
  }
}

.author-wrapper {
  @media (max-width: 500px) {
    width: 100%;
    margin-bottom: -15px;
  }
}

.sectors-wrapper, .sector {
  @media (max-width: 991px) {
    display: none;
  }
}


@media (max-width: 991px) {
  .website-show {
    display: none !important;
  }

  .mobile-show {
    display: block !important;
  }
}


@media (min-width: 992px) {

  .website-show {
    display: block !important;
  }

  .website-hide {
    display: none !important;
  }

  .mobile-show {
    display: none !important;
  }
}

