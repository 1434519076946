.organisation-structures {
  .lSSlideOuter {
    .lSPager.lSGallery {
      max-height: 60px !important;
    }
  }

  .organisation-structures__light_slider {
    img {
      max-width: 100%;
    }
  }
  .organisation-structures__content {
    margin-bottom: 80px;

    .organisation-structures__content__item {
      &:not(:first-of-type) {
        margin-top: 40px;
      }
    }

    .base-header--line-after {
      margin-bottom: 40px;

      .base-header--line-after__title {
        text-transform: uppercase;
      }
    }

    .organisation-structures__content__text__wrap {
      padding-right: 60px;

      @media (max-width: 992px) {
        padding-right: 0;
        margin-bottom: 30px;
      }

      .organisation-structures__content__text {
        font-size: 13px;
      }
    }

    .organisation-structures__content__column__wrap {
      margin-right: -15px;
      margin-top: -15px;

      @media (max-width: 992px) {
        margin-left: -15px;
      }

      .organisation-structures__content__column {
        padding: 15px;
        position: relative;
        min-height: 120px;

        @media (max-width: 992px) {
          width: (100/12) * 6%;
        }

        @media (max-width: 700px) {
          width: 100%;
        }

        .organisation-structures__content__column__image {
          position: absolute;
          z-index: 10;
          border-radius: 100px;
          top: 20px;
          border: 5px solid $color-white;
          background: $color-white;

          &.active {
            z-index: 9999;
            left: 0;

            div {
              width: 160px;
              height: 160px;
            }
          }

          div {
            width: 45px;
            height: 45px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            border-radius: 50%;
          }
        }

        .organisation-structures__content__column__wrapper {
          position: relative;
          background: $color-gray;
          clip-path: polygon(5% 0, 100% 0, 100% 100%, 0% 100%);
          -webkit-clip-path: polygon(5% 0, 100% 0, 100% 100%, 0% 100%);
          width: calc(100% - 25px);
          float: right;

          &.active {
            -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
            -moz-animation: fadein 0.5s; /* Firefox < 16 */
            -ms-animation: fadein 0.5s; /* Internet Explorer */
            -o-animation: fadein 0.5s; /* Opera < 12.1 */
            animation: fadein 0.5s;

            @keyframes fadein {
              from { opacity: 0; }
              to   { opacity: 1; }
            }

            /* Firefox < 16 */
            @-moz-keyframes fadein {
              from { opacity: 0; }
              to   { opacity: 1; }
            }

            /* Safari, Chrome and Opera > 12.1 */
            @-webkit-keyframes fadein {
              from { opacity: 0; }
              to   { opacity: 1; }
            }

            position: absolute;
            width: calc(100% - 55px);
            right: 15px;
            z-index: 999;

            .organisation-structures__content__wrapper {
              .organisation-structures__content__wrapper__text {
                padding: 24px 20px 24px 120px;
                min-height: 180px;
              }
            }
          }

          .organisation-structures__content__wrapper {
            position: relative;

            .organisation-structures__content__wrapper__text {
              padding: 24px 20px;

              .organisation-structures__content__wrapper__text--name {
                position: relative;
                color: $color-blue;
                font-size: 14px;
                margin-left: 30px;
                font-weight: bold;
                display: block;
                margin-right: 70px;
              }

              .organisation-structures__content__wrapper__text--info {
                position: absolute;
                right: 20px;
                top: 24px;

                span {
                  float: right;

                  &.icon-phone {
                    font-weight: bold;
                  }

                  &:not(:last-of-type) {
                    margin-left: 10px;
                  }

                  &::before {
                    font-size: 14px;
                    color: $color-blue;
                  }
                }
              }

              .organisation-structures__content__wrapper__text--title {
                font-weight: normal;
                position: relative;
                color: $color-blue;
                font-size: 14px;
                margin-left: 30px;
                display: block;
              }

              .organisation-structures__content__wrapper__text--description {
                display: none;
              }

              .organisation-structures__content__wrapper__text--contact {
                display: none;

                &.active {
                  margin-top: 20px;
                  display: block;
                }

                span {
                  color: $color-blue;
                  font-size: 14px;
                  font-weight: bold;
                }
              }
            }
          }

          &.active {
            background: $color-blue;

            .organisation-structures__content__wrapper {
              .organisation-structures__content__wrapper__text {
                .organisation-structures__content__wrapper__text--name {
                  color: $color-white;
                }

                .organisation-structures__content__wrapper__text--info {
                  span {
                    &::before {
                      color: $color-white;
                    }
                  }
                }

                .organisation-structures__content__wrapper__text--title {
                  color: $color-white;
                }

                .organisation-structures__content__wrapper__text--description {
                   font-weight: normal;
                   position: relative;
                   color: $color-white;
                   font-size: 14px;
                   margin-left: 30px;
                   margin-top: 20px;
                   display: block;
                 }

                .organisation-structures__content__wrapper__text--contact {
                  color: $color-white;

                  .organisation-structures__content__wrapper__text--contact__item {
                    display: block;
                    margin-left: 30px;
                    font-weight: bold;

                    a {
                      color: $color-white;
                    }

                    &:not(:first-of-type) {
                      margin-top: 10px;
                    }

                    span {
                      color: $color-white;
                      margin-right: 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .organisation-structures__assembly_logo {
    max-width: 100%;
  }
}
