.about-us__contact {
  .about-us__contact__text {
    font-size: 13px;
    line-height: 1.8em;
  }
}

.about-us__contact__block--row {
  margin-left: -10px;
  margin-right: -10px;

  .small-blocks {
    @media (max-width: 1400px) {
      width: (100/12) * 12% !important;
    }

    .about-us__contact__block--wrapper {
      @media (max-width: 1199px) {
        width: (100/12) * 12% !important;
      }
    }

    @media (max-width: 1199px) {
      width: (100/12) * 6% !important;
    }

    @media (max-width: 576px) {
      width: 100% !important;
    }
  }

  .about-us__contact__block--wrapper {
    padding: 0 10px;
    margin-bottom: 20px;
    min-width: 200px;

    @media (max-width: 1400px) {
      width: (100/12) * 12% !important;
    }

    @media (max-width: 1199px) {
      width: (100/12) * 6% !important;
    }

    @media (max-width: 576px) {
      width: 100% !important;
    }

    &.big-block {
      .about-us__contact__block {
        background: $color-blue;
        color: $color-white;
        padding: 0 8px;

        .about-us__contact__block--text {
          .about-us__contact__block--description {
            a {
              color: $color-white;
            }
          }
        }

        &.small-block {
          padding: 15px 8px;
        }
      }
    }

    .about-us__contact__block {
      position: relative;
      background: $color-gray;
      color: $color-blue;
      padding: 8px;

      &.small-block {
        padding: 15px;
      }

      &:not(.big-block) {
        -webkit-clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
        clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
      }

      .about-us__contact__block--icon {
        position: absolute;
        top: 0;
        display: flex;
        bottom: 0;
        align-items: center;
        -webkit-box-align: center;
        margin: auto;

        &::before {
          font-size: 25px;
        }
      }

      .about-us__contact__block--text {
        padding-left: 40px;

        .about-us__contact__block--title {
          display: block;
          font-weight: bold;
          font-size: 14px;
        }

        .about-us__contact__block--description {
          display: block;
          font-size: 13px;

          a {
            color: $color-black;
          }
        }
      }
    }
  }
}

.about-us__commanders__row {
  margin-right: -15px;

  .about-us__commanders__column {
    padding: 15px 15px;
    position: relative;

    @media (max-width: 1199px) {
      width: (100/12) * 6% !important;
    }

    @media (max-width: 800px) {
      width: 100% !important;
    }

    .about-us__commanders__column__image {
      position: absolute;
      z-index: 10;
      border-radius: 100px;
      top: 0;
      border: 5px solid $color-white;
      background: $color-white;
      left: 0;
      display: flex;
      bottom: 0;
      align-items: center;
      -webkit-box-align: center;
      margin: auto;
      height: 90px;
      width: 90px;

      div {
        width: 80px;
        height: 80px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 50%;
      }
    }

    .about-us__commanders__column__wrapper {
      position: relative;
      background: $color-gray;
      clip-path: polygon(5% 0, 100% 0, 100% 100%, 0% 100%);
      -webkit-clip-path: polygon(5% 0, 100% 0, 100% 100%, 0% 100%);
      width: calc(100% - 25px);
      float: right;
      -webkit-transition: height, 0.3s linear;
      transition: height, 0.3s linear;

      .about-us__commanders__wrapper {
        position: relative;

        .about-us__commanders__wrapper__text {
          padding: 14px 20px;

          .about-us__commanders__wrapper__text--name {
            position: relative;
            color: $color-blue;
            font-size: 14px;
            margin-left: 45px;
            font-weight: bold;
            display: block;
            margin-right: 70px;
          }

          .about-us__commanders__wrapper__text--title {
            font-weight: normal;
            position: relative;
            color: $color-blue;
            font-size: 14px;
            margin-left: 45px;
            display: block;
          }
        }
      }
    }
  }
}


.about-us__commanders__read-button {
  float: right;
  margin-top: 10px;

  a {
    font-size: 14px;
    color: $color-blue;
    font-weight: bold;
  }
}

.about-us__document-clip {
  -webkit-clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
}

.about-us__document-wrapper {
  background: $color-gray;
  position: relative;
  .document-name {
    display: block;
    color: $color-blue;
  }
  .about-us__document-wrapper--image {
    position: absolute;
    top: 0;
    display: flex;
    bottom: 0;
    align-items: center;
    -webkit-box-align: center;
    margin: auto;
    left: 15px;

    img {
      -webkit-box-shadow: 0 0 8px -5px #000;
      box-shadow: 0 0 8px -5px #000;
      padding: 40px 20px;
      background: $color-white;
      width: 120px;
    }

    max-width: 120px;
  }

  .about-us__document-wrapper--name {
    padding: 20px;
    font-weight: 600;
    font-size: 14px;
    padding-left: 155px;
    padding-right: 40px;
    background: $color-blue;

    .document-name {
      display: block;
      color: $color-white;
    }

    .document-size {
      display: block;
      color: $color-gray-darker;
      margin-top: 5px;
    }
  }
}
