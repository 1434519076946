
.article-detail__content {
  line-height: 2em;
}

.content-dynamic-tag {
  white-space: normal !important;
  display: inline-block !important;
  padding-left: 7px;

  &:hover {
    cursor: pointer;
  }
}

.content-dynamic-tag__tooltip {
  &.show {
    opacity: 1;
  }

  .articles__container__wrapper {
    margin-left: 0;
    padding: 0;

    .articles__container__header,
    .articles__container__read-button span {
      color: $color-white !important;
    }
  }

  .tooltip-arrow {
    display: none;
  }

  .tooltip-inner {
    clip-path: polygon(0 0, 95% 0, 100% 100%, 0% 100%);
    -webkit-clip-path: polygon(0 0, 95% 0, 100% 100%, 0% 100%);
    text-align: left;
    max-width: 500px;
    width: 500px;
    padding: 20px 40px 20px 20px;
    background: $color-blue;
  }
}


.trapezoid {
  &::after {
    background: $color-gray;
    content: '';
    top: 0;
    height: 100%;
    width: 25px;
    position: absolute;
    display: block;
    z-index: -1;
    left: 0;
    clip-path: polygon(0 0, 77% 0, 100% 100%, 0% 100%);
    -webkit-clip-path: polygon(0 0, 77% 0, 100% 100%, 0% 100%);
  }

  white-space: nowrap;
  position: relative;
  display: block;
  z-index: 999;
  font-weight: bold;
  color: $color-blue;
  //margin-left: 50px;
  //margin-top: 50px;
  //padding: 25px 50px;
}

.loader__wrapper {
  text-align: center;
  display: none;

  &.show {
    display: block;
  }

  .loader {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .loader div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: $color-blue;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  .loader div:nth-child(1) {
    left: 8px;
    animation: loader1 0.6s infinite;
  }

  .loader div:nth-child(2) {
    left: 8px;
    animation: loader2 0.6s infinite;
  }

  .loader div:nth-child(3) {
    left: 32px;
    animation: loader2 0.6s infinite;
  }

  .loader div:nth-child(4) {
    left: 56px;
    animation: loader3 0.6s infinite;
  }

  @keyframes loader1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes loader3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }

  @keyframes loader2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
}
