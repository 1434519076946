$color-blue : #1d2654;
$color-gray : #f4f4f4;
$color-gray-darker : #b2b2b2;
$color-black : #000;
$color-white : #fff;
$color-yellow : #fec969;
$color-red : #dc3545;
$color-green : #00c292;

$themecolor: $color-white;
$themecolor-alt: $color-blue;
$sidebar: $color-blue;
$sidebar-alt:#f9f9f9;
$sidebar-text: $color-white;
$shadow:1px 0 20px rgba(0, 0, 0, 0.08);
$lft:left;
$rgt:right;
$body-bg: $color-gray;
$input-border-grey: #ced4da;

$font-family-1: 'Roboto', sans-serif;
$font-family-2: 'Barlow', sans-serif;
$font-family-3: 'Barlow Condensed', sans-serif;
$font-family-4: 'icomoon';
