.members {
  .members__content {
    margin-bottom: 80px;

    .content-block--left {
      padding-right: 30px;

      @media (max-width: 992px) {
        padding-right: 0;
      }
    }

    .members__content__members {
      .members__content__header {
        position: relative;
        margin-bottom: 20px;
        margin-top: 50px;

        .members__content__header__count {
          position: relative;
          float: right;
          z-index: 100;
          padding-left: 20px;
          background: $color-white;

          .members__content__header__count--wrapper {
            position: relative;
            width: 40px;
            height: 40px;
            border-radius: 30px;
            background: $color-blue;
            text-align: center;
          }

          span {
            font-size: 17px;
            font-family: $font-family-3;
            font-weight: bold;
            color: $color-white;
            position: absolute;
            top: 6px;
            left: 0;
            right: 0;
          }
        }
      }

      .members__content__column__unwrap {
        display: none;

        @media (max-width: 700px) {
          display: block;
        }

        .members__content__column__unwrap--collapse {
          display: none;
          font-size: 1.2em;
          font-weight: bold;
          text-align: right;
          padding-right: 15px;
          color: $color-gray-darker;

          &.show {
            display: block;
          }

          .members__content__column__unwrap__text {
            font-size: 0.7em;
            margin-right: 10px;

            &:hover {
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }

        .members__content__column__unwrap--expand {
          display: none;
          font-size: 1.2em;
          font-weight: bold;
          text-align: right;
          padding-right: 15px;
          color: $color-gray-darker;

          &.show {
            display: block;
          }

          .members__content__column__unwrap__text {
            font-size: 0.7em;
            margin-right: 10px;

            &:hover {
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }
      }

      .members__content__column__wrap {
        margin-left: -15px;
        margin-right: -15px;

        @media (max-width: 700px) {
          display: none;

          &.show {
            display: block;
          }
        }

        .members__content__column {
          padding: 15px;
          position: relative;
          min-height: 100px;

          @media (max-width: 992px) {
            width: (100/12) * 6%;
          }

          @media (max-width: 700px) {
            width: 100%;
          }

          .members__content__column__image {
            position: absolute;
            z-index: 10;
            border-radius: 100px;
            top: 20px;
            border: 5px solid $color-white;
            background: $color-white;

            &.active {
              z-index: 9999;
              left: 0;

              div {
                width: 160px;
                height: 160px;
              }
            }

            &.active {
              z-index: 9999;
            }

            div {
              width: 45px;
              height: 45px;
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;
              border-radius: 50%;
            }
          }

          .members__content__column__wrapper {
            position: relative;
            background: $color-gray;
            clip-path: polygon(5% 0, 100% 0, 100% 100%, 0% 100%);
            -webkit-clip-path: polygon(5% 0, 100% 0, 100% 100%, 0% 100%);
            width: calc(100% - 25px);
            float: right;

            &.active {
              -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
              -moz-animation: fadein 0.5s; /* Firefox < 16 */
              -ms-animation: fadein 0.5s; /* Internet Explorer */
              -o-animation: fadein 0.5s; /* Opera < 12.1 */
              animation: fadein 0.5s;

              @keyframes fadein {
                from { opacity: 0; }
                to   { opacity: 1; }
              }

              /* Firefox < 16 */
              @-moz-keyframes fadein {
                from { opacity: 0; }
                to   { opacity: 1; }
              }

              /* Safari, Chrome and Opera > 12.1 */
              @-webkit-keyframes fadein {
                from { opacity: 0; }
                to   { opacity: 1; }
              }

              position: absolute;
              width: calc(100% - 55px);
              right: 15px;
              z-index: 999;

              .members__content__wrapper__text {
                padding: 24px 20px 24px 120px !important;
                min-height: 180px;
              }
            }

            .members__content__wrapper {
              position: relative;

              .members__content__wrapper__text {
                padding: 24px 20px;

                .members__content__wrapper__text--name {
                  position: relative;
                  color: $color-blue;
                  font-size: 14px;
                  margin-left: 30px;
                  font-weight: bold;
                  display: block;
                  margin-right: 15px;
                }

                .members__content__wrapper__text--description {
                  display: none;
                  position: relative;
                  font-size: 12px;
                  margin-left: 30px;
                  line-height: 20px;

                  &.active {
                    margin-top: 30px;
                    display: block;
                  }
                }

                .members__content__wrapper__text--arrow {
                  position: absolute;
                  top: 0;
                  right: -20px;
                  display: flex;
                  bottom: 0;
                  align-items: center;
                  -webkit-box-align: center;
                  margin: auto;

                  a {
                    font-weight: bold;
                    text-decoration: unset;
                    font-size: 15px;
                    color: $color-blue;

                    &:hover {
                      text-decoration: underline;
                    }
                  }
                }
              }
            }

            &.active {
              background: $color-blue;

              .members__content__wrapper {
                .members__content__wrapper__text {
                  .members__content__wrapper__text--name {
                    color: $color-white;
                  }

                  .members__content__wrapper__text--description {
                    color: $color-white;
                  }

                  .members__content__wrapper__text--arrow {
                    a {
                      color: $color-white;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .content-block--right {
      .members__content__tips__header {
        font-family: $font-family-3;
        font-weight: bold;
        font-size: 24px;
        color: $color-blue;
        margin-top: 50px;
        margin-bottom: 12px;
        position: relative;
        display: block;
        text-transform: uppercase;
      }

      .members__content__tips__text {
        font-size: 13px;
        margin-bottom: 24px;
        display: block;
        line-height: 22px;

        &.colored {
          clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
          background: $color-blue;
          color: $color-white;
          margin-left: -25px;
          padding: 20px 30px 20px 25px;

          @media (max-width: 992px) {
            margin-left: unset;
          }
        }
      }

      .members__content__tips__cons {
        position: relative;
        padding-left: 65px;
        padding-top: 7px;

        &:not(:first-of-type) {
          margin-top: 32px;
        }

        .trapezoid {
          position: absolute;
          left: 0;
          top: 0;

          &::after {
            width: 45px;
            background: $color-blue;
            clip-path: polygon(0 0, 90% 0, 100% 100%, 0% 100%);
            -webkit-clip-path: polygon(0 0, 90% 0, 100% 100%, 0% 100%);
          }

          color: $color-white;
          font-size: 15px;
          margin-left: 0;
          margin-top: 0;
          padding: 10px 14px;
        }

        .members__content__tips__cons__header {
          font-size: 18px;
          color: $color-blue;
          display: block;
          font-weight: bold;
          margin-bottom: 8px;
        }

        .members__content__tips__cons__context {
          font-size: 14px;
          color: $color-black;
        }
      }
    }
  }
}
