.profile {
  .content-block--right {
    padding-left: 50px;

    @media (max-width: 1199px) {
      padding-left: unset;
    }
  }

  &.article-detail__wrapper {
    margin-bottom: 80px;
  }
}

.profile-team-without-article {
  .team__content__column {
    padding: 15px;

    .team__content__column__image {
      top: 18px;
    }
  }
}

.team__content__column {
  //padding: 15px;
  position: relative;
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: unset;
  }

  @media (max-width: 992px) {
    width: 100%;
  }

  .team__content__column__image {
    position: absolute;
    z-index: 10;
    border-radius: 100px;
    top: 10px;
    border: 5px solid $color-white;
    background: $color-white;

    div {
      width: 90px;
      height: 90px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border-radius: 50%;
    }
  }

  .team__content__column__wrapper {
    position: relative;
    background: $color-gray;
    clip-path: polygon(5% 0, 100% 0, 100% 100%, 0% 100%);
    -webkit-clip-path: polygon(5% 0, 100% 0, 100% 100%, 0% 100%);
    width: calc(100% - 25px);
    margin-left: 25px;
    //-webkit-transition: height, 0.3s linear;
    //transition: height, 0.3s linear;
    min-height: 120px;

    .team__content__wrapper {
      position: relative;

      .team__content__wrapper__text {
        padding: 10px 30px 10px 60px;
        position: relative;

        .team__content__wrapper__text--name {
          position: relative;
          color: $color-blue;
          font-size: 14px;
          margin-left: 30px;
          font-weight: bold;
          display: block;
          margin-right: 15px;
        }

        .team__content__wrapper__text--title {
          position: relative;
          color: $color-blue;
          font-size: 14px;
          margin-left: 30px;
          font-weight: normal;
          display: block;
          margin-right: 15px;
          margin-bottom: 10px;
        }

        .team__content__wrapper__text--email {
          a {
            position: relative;
            color: $color-blue;
            font-size: 12px;
            margin-left: 30px;
            font-weight: normal;
            display: block;
            margin-right: 15px;
          }
        }

        .team__content__wrapper__text--phone {
          position: relative;
          color: $color-blue;
          font-size: 12px;
          margin-left: 30px;
          font-weight: normal;
          display: block;
          margin-right: 15px;
        }

        .team__content__wrapper__text--description {
          display: block;
          position: relative;
          font-size: 12px;
          margin-left: 30px;
          line-height: 20px;
          margin-top: 10px;
        }
      }
    }

    .team__content__wrapper__text--arrow {
      position: absolute;
      top: 0;
      right: 8px;
      display: flex;
      bottom: 0;
      align-items: center;
      -webkit-box-align: center;
      margin: auto;

      a {
        font-weight: bold;
        text-decoration: unset;
        font-size: 15px;
        color: $color-blue;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
